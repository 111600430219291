




















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {BCol, BRow} from "bootstrap-vue";
import Div from "@/components/Div.vue";

@Component({
    name: 'Layout',
    components: {
        Layout
    },
})
export default class Layout extends Vue {
    @Prop() private readonly layout!: any

    getComponent(l: any){
        if(this.isLeaf(l)){
            return Div
        }
        if(l.tag === 'BRow'){
            return BRow
        }
        if(l.tag === 'BCol'){
            return BCol
        }
        if(l.tag === 'div'){
            return Div
        }
        return Div
    }

    isLeaf(l: any){
         return typeof l == 'string'
    }
}
