






import {Component, Vue} from 'vue-property-decorator';

@Component({
    name: 'Div',
    components: {
    },
})
export default class Div extends Vue {
}
