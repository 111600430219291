






















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
    name: 'BaseInput',
})
export default class BaseInput extends Vue {
}
