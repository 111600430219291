
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import BaseComponent from "@/components/BaseComponent";

@Component({
    name: "FormComponent"
})
export default class FormComponent extends BaseComponent {
    private model: object = {};


}

