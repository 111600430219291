






































import {Component, Prop, Watch} from 'vue-property-decorator';
import ListComponent from "@/components/ListComponent";

@Component({
    name: "List"
})
export default class List extends ListComponent {
    @Prop(String) protected readonly keyField!: string;
    @Prop(String) protected readonly valueField!: string;

    get filterId() {
        return 'filterInput_' + this.id;
    }

    get filteredItems() {
        // todo: implementare filtro lato front
        return this.items
    }

    @Watch('filter')
    onFilterChange() {
        this.fetch();
    }

    @Watch('currentPage')
    onCurrentPageChange() {
        this.fetch();
    }

    init() {
        this.fetch();
    }

}
