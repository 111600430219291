


















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Autocomplete, FormLayout, ModelCreatedResponse} from "@/interfaces/ApiInfo";
import Multiselect from "vue-multiselect";

@Component({
    name: 'DjVueAcSelect',
    components: {
        Multiselect
    },
})
export default class DjVueAcSelect extends Vue {
    @Prop() private readonly name!: string;
    @Prop() private readonly baseUrl!: string;
    @Prop() private readonly createModelPath!: string;
    @Prop() private readonly options!: Autocomplete[];
    @Prop() private readonly placeholder!: string;
    @Prop() private readonly ac!: boolean;
    @Prop() private readonly value!: number[] | number | undefined;
    @Prop() private readonly state!: boolean | null;
    @Prop() private readonly allowCreation!: boolean | null;
    @Prop() private readonly multiple!: boolean;
    @Prop() private readonly required!: boolean;
    @Prop() private readonly disabled!: boolean;

    private options_: Autocomplete[] = this.options || []
    private isLoading = false
    private cancel: any;
    private cancelTimeout: number = -1;

    /**
     * utilizzo un setter ed un getter per ritornare il valore al componente padre, per il componente vue-multiselect
     * continuo ad utilizzare la struttura delle opzioni in quanto mi risolve delle beghe relativamente alla ricerca
     * tramite ajax, altrimenti sarei costretto ad utilizzare delle strutture di supporto e mantenerne una consistenza
     */
    get MsVmodel() {
        if (this.multiple) {
            return this.Options.filter((opt) => {
                return (this.value as number[]).includes(opt.id)
            })
        }
        return this.Options.find((opt) => {
            return this.value === opt.id
        })
    }

    set MsVmodel(vls: Autocomplete[] | Autocomplete | undefined) {
        if (this.multiple) {
            this.$emit('input', (vls as Autocomplete[]).map((vl) => {
                return vl.id
            }))
        } else {
            /**
             * Vue multiselect permette di deselezionare il valore e mette il vls a null, quindi prima di lanciare l'evento
             * controllo che esista il valore, questo comportamento avviene solamente quando il campo non è required
             * altrimenti il campo vls è sempre popolato
             */
            let id = null
            if (vls) {
                id = (vls as Autocomplete).id;
            }
            this.$emit('input', id)
        }
    }

    get Options() {
        return this.options_
    }

    set Options(opt: Autocomplete[]) {
        this.options_ = opt
    }

    toLabel(ac: Autocomplete) {
        return ac.text
    }

    created() {
        this.$root.$on(['dv::object::created', 'dv::object::updated'], (evt: ModelCreatedResponse) => {
            //non è detto che la cosa sia necessariamente giusta, potrei avere più url che creano un modello
            if (evt.baseUrl == this.createModelPath) {
                this.search('', true)
            }
        })
    }

    //questo serve perché se la form effettua la get dopo che il componente si è renderizzato non vedo le modifiche
    @Watch('value')
    onValueChange() {
        this.search('', true);
    }

    mounted() {
        this.search('', true)
    }

    fetchOptions(query: string, ids?: string) {
        this.isLoading = true
        Vue.axios.get(`${this.baseUrl}autocomplete/?field=${this.name}&search=${query}&ids=${ids}`, {
            cancelToken: new Vue.axios.CancelToken((c) => {
                // An executor function receives a cancel function as a parameter
                this.cancel = c;
            })
        }).then(response => {
            this.Options = response.data
        }).catch((err) => {
            if (Vue.axios.isCancel(err)) {
                console.warn('Request canceled');
            } else {
                // handle error
            }
        }).finally(() => {
            this.isLoading = false
        })
    }

    search(query: string, force?: boolean) {

        let ids: number[] = []
        if (this.ac) {
            if (this.cancel) {
                this.cancel()
            }
            if (this.value) {
                if (this.value.constructor != Array) {
                    ids = [this.value as number]

                } else {
                    ids = this.value as number[]
                }
            }
            window.clearTimeout(this.cancelTimeout)
            if (force) {
                this.fetchOptions(query, ids.join(','))
            } else {
                this.cancelTimeout = window.setTimeout(() => {
                    this.fetchOptions(query, ids.join(','))
                }, 200);
            }
        }
    }
}
